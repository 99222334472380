import { useState } from 'react';

export const DocumentView = ({
	frontImage,
	backImage,
}: {
	backImage: string;
	frontImage: string;
}) => {
	const [isError, setIsError] = useState({
		front: false,
		back: false,
	});

	return (
		<div className="kycDetails-info__documentView_inner">
			{!isError.front && (
				<div className="kycDetails-info__documentView_inner_img">
					<img
						src={frontImage}
						alt="pic"
						onClick={() => window.open(frontImage)}
						onError={() => {
							setIsError(prev => ({ ...prev, front: true }));
						}}
					/>
				</div>
			)}
			{!isError.back && (
				<div className="kycDetails-info__documentView_inner_img">
					<img
						src={backImage}
						alt="pic"
						onClick={() => window.open(backImage)}
						onError={() => {
							setIsError(prev => ({ ...prev, back: true }));
						}}
					/>
				</div>
			)}
		</div>
	);
};
